.container {
  display: flex;
  flex-direction: column;
}

.parent {
  /* text-align: center; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.output {
  background: linear-gradient(330deg, #eaeaea 0, #fafafa 100%);
  min-height: 240px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-field {
  height: 38px;
  width: 184px;
  padding: 0px 8px;
  border-radius: 8px;
}

.has-border {
  border: 0.5px solid rgba(60, 65, 70, 0.07);
  /* border-radius: 2px; */
}

.has-box-shadow {
  box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.1),
    0px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.08),
    inset 0px 0px 2px 1px #ffffff;
}

.remove-outline {
  outline: none;
}
